body {
  --input-bg: theme('colors.light.300');
  --input-border-color: theme('colors.light.400');
  --input-color: theme('colors.gray.charcoal');
  --input-placeholder-color: theme('colors.silver');
  --input-group-addon-color: theme('colors.silver-112');
  --popover-border-color: theme('colors.light.300');
  --popover-bg: theme('colors.white');
  --popover-body-color: theme('colors.gunmetal');
  --popover-header-bg: theme('colors.light.305');
  --popover-arrow-color: theme('colors.light.300');
  --modal-content-bg: theme('colors.white');
  --modal-content-border-color: theme('colors.light.300');
  --list-group-bg: theme('colors.white');
  --list-group-border-color: theme('colors.black-weak');
  --list-group-action-color: theme('colors.gray.700');
  --list-group-hover-bg: theme('colors.gray.100');
  --list-group-action-active-bg: theme('colors.gray.200');
  --table-accent-bg: theme('colors.light.300');
  --close-color: null default;
  --table-color: theme('colors.gray.steel-005');
  --table-border-color: theme('colors.light.300');
  --pagination-bg: theme('colors.white');
  --pagination-border-color: theme('colors.light.400');
  --pagination-hover-bg: theme('colors.light.300');
  --pagination-hover-border-color: theme('colors.light.400');
  --card-border-color: theme('colors.light.300');
  --card-cap-bg: theme('colors.light.200');
  --border-color: theme('colors.gray.300');

  background: theme('colors.white');
  color: theme('colors.gray.charcoal-100');
}