@import "animations";

@import "./renderer.css";
@import "react-grid-layout/css/styles.css";
@import "react-resizable/css/styles.css";

.vote-svg {
  width: 8px !important;
  max-height: 6px !important;
}

.slider-svg-up {
  width: 12px !important;
  max-height: 7px !important;
  margin-bottom: 1px;
}

.slider-svg-down {
  width: 12px !important;
  max-height: 7px !important;
  margin-top: 1px;
}

.medium-zoom--opened .medium-zoom-overlay {
  z-index: 20;
  opacity: 0.75 !important;
}

.medium-zoom-image--opened {
  z-index: 20;
}